<template>
  <div>
    <div id="map"></div>
    <!-- <div class="mapBox">주차장명 : 5 대</div> -->
  </div>
</template>

<script>
//TODO: 필수 교체
const KAKAO_MAP_API_KEY = `${process.env.VUE_APP_KAKAO_MAP_API_KEY}`;

export default {
  name: 'KakaoMap',
  props: {
    centerPositions: [],
    parkingList: [],
    park: String,
    isSingleElement: Boolean,
  },

  data() {
    return {
      markers: [],
      positions: [],
      customOverlay: [],
      ckImg: null,
      imageSize: null,
      imageOption: null,
      map: null,
    };
  },

  watch: {
    parkingList() {
      if (this.parkingList?.length >= 0) {
        this.initMap();
      }
    },
  },

  mounted() {
    if (window.kakao && window.kakao.maps) {
      if (this.isSingleElement) {
        this.initMap();
      } else {
        kakao.maps.load(() => this.initMap);
      }
      return;
    }
    const script = document.createElement('script');
    script.onload = () => kakao.maps.load(this.initMap);
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=${KAKAO_MAP_API_KEY}`;
    document.head.appendChild(script);
  },

  methods: {
    // 파랑 마커
    mapMarkerBlue() {
      return require('../assets/images/map/map_marker_blue.png');
    },

    // 빨강 마커
    mapMarkerRed() {
      return require('../assets/images/map/map_marker_red.png');
    },

    // 주황 마커
    mapMarkerOrg() {
      return require('../assets/images/map/map_marker_org.png');
    },

    // 초록 마커
    mapMarkerGrn() {
      return require('../assets/images/map/map_marker_green.png');
    },

    // 초록 마커
    mapMarkerBlck() {
      return require('../assets/images/map/map_marker_black.png');
    },

    initMap() {
      if (this.park != undefined) {
        document.getElementById('map').setAttribute('style', 'height: 350px');
      }

      const container = document.getElementById('map');

      try {
        const options = {
          center: new kakao.maps.LatLng(this.centerPositions[0], this.centerPositions[1]),
          level: 5,
        };
        if (this.map == null) {
          this.map = new kakao.maps.Map(container, options);
        }
        this.map.setCenter(new kakao.maps.LatLng(this.centerPositions[0], this.centerPositions[1]));
        this.displayMarker();
      } catch (ReferenceError) {
        return;
      }
    },

    displayMarker() {
      if (this.markers != []) {
        for (const i of this.markers) {
          i.setMap(null);
        }
        this.markers = [];
      }
      this.positions = [];

      const bounds = new kakao.maps.LatLngBounds();

      // 마커이미지의 크기입니다
      this.imageSize = new kakao.maps.Size(25, 35);
      this.imageOption = { offset: new kakao.maps.Point(12, 34) };

      for (let i = 0; i < this.parkingList.length; i++) {
        if (this.parkingList[i].LAT % 1 != 0 && !isNaN(this.parkingList[i].LAT) && this.parkingList[i].LAT != null) {
          let content = '';
          const position = new kakao.maps.LatLng(this.parkingList[i].LAT, this.parkingList[i].LNG);
          this.positions.push(position);

          const parkingCnt =
            ((this.parkingList[i].CELL_CNT - this.parkingList[i].PARK_COUNT) / this.parkingList[i].CELL_CNT) * 100;

          this.markers.push(
            new kakao.maps.Marker({
              map: this.map,
              position: position,
              clickable: true,
              image: new kakao.maps.MarkerImage(
                this.setMarkerImages(parkingCnt, this.parkingList[i].PARKING_GUBUN),
                this.imageSize,
                this.imageOption,
              ),
            }),
          );

          if (this.parkingList[i].PARK_COUNT == null || this.parkingList[i].PARK_COUNT < 0) {
            this.parkingList[i].PARK_COUNT = 0;
          }

          content =
            '<div class="mapBox">' +
            '<div>' +
            '<strong>' +
            this.parkingList[i].PARKING_NM +
            '</strong>' +
            ' ' +
            this.parkingList[i].PARK_COUNT +
            '면' +
            '</div>' +
            '</div>';

          this.customOverlay[i] = new kakao.maps.CustomOverlay({
            position: this.positions[i],
            content: content,
            yAnchor: 0.1,
          });

          bounds.extend(position);
        }
      }
      if (bounds.ha != Infinity) {
        this.map.setBounds(bounds);
      }
      this.$emit('kakaoMapMarker', this.markers, this.map, this.positions, this.customOverlay, this.parkingList);
    },

    setMarkerImages(parkingCnt) {
      if (parkingCnt >= 0 && parkingCnt < 60) {
        return this.mapMarkerBlue();
      } else if (parkingCnt >= 60 && parkingCnt < 80) {
        return this.mapMarkerOrg();
      } else if (parkingCnt >= 80) {
        return this.mapMarkerRed();
      } else {
        return this.mapMarkerBlue();
      }
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 700px;
}
</style>
