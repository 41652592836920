<template>
  <article>
    <!--바로가기 navi-->
    <div id="accNav">
      <router-link to="#content">본문 바로가기</router-link>
      <router-link to="#footer">푸터 바로가기</router-link>
    </div>

    <!-- mobile -->
    <header id="mobile">
      <div class="inner">
        <div class="top">
          <h1 class="logo">
            <router-link to="/main">
              <div class="logoImg"></div>
              <div>
                <p>안양도시공사</p>
                <p>통합주차포털</p>
              </div>
            </router-link>
          </h1>
          <button type="button" @click="menubtn" class="moTopMenu">
            <span class="hidden">메뉴</span>
          </button>
        </div>
        <div class="bottom">
          <article class="searchBar">
            <label for="searchMobile" class="hidden">검색어를 입력하세요.</label>
            <input
              type="text"
              id="searchMobile"
              title="검색창 입력"
              placeholder="검색어를 입력하세요."
              v-model="searchKeyword"
              @focus="focused"
              @blur="focusOut"
              @keydown.enter="search"
            />
            <button type="button" class="button searchBtn" @click="search"><span class="hidden">검색</span></button>
          </article>
        </div>
      </div>
      <nav class="gnb" v-show="btnmenu">
        <article class="gnbHead">
          <div class="utill">
            <!-- Logout -->

            <ul v-if="isLogin">
              <li class="welcome">
                <span>{{ empNm }}</span
                >님
              </li>
              <li>
                <button type="button" class="btn logout" @click="actionLogout">로그아웃</button>
              </li>
              <li>
                <router-link
                  :to="{ path: isBusiness ? '/mypagebusiness' : '/mypagegeneral' }"
                  class="btn green mypage"
                  title="마이페이지"
                  >마이페이지
                </router-link>
              </li>
            </ul>

            <!-- Login -->
            <ul v-else>
              <li>
                <router-link to="/ssomemberlogin" class="btn blue login" title="로그인">로그인 </router-link>
              </li>
              <li>
                <router-link to="/join" class="btn green join" title="회원가입">회원가입</router-link>
              </li>
            </ul>
          </div>
        </article>
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, index) in firDepth" :key="index">
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels>
                <v-expansion-panel v-for="(secDepth, index) in item.menuList" :key="index">
                  <v-expansion-panel v-if="secDepth.menuList === undefined" readonly>
                    <v-expansion-panel-header>
                      <router-link :to="secDepth.link">{{ secDepth.title }}</router-link>
                    </v-expansion-panel-header>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="secDepth.menuList !== undefined">
                    <v-expansion-panel-header class="subMenu">
                      {{ secDepth.title }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list>
                        <v-list-item v-for="(trdDepth, index) in secDepth.menuList" :key="index">
                          <v-list-item-content>
                            <router-link :to="trdDepth.link">{{ trdDepth.title }}</router-link>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </nav>
    </header>
    <!-- mobile end -->

    <!-- pc -->
    <header id="web">
      <!--top-->
      <div class="top" @click="modalHide">
        <div class="inner">
          <h1 class="logo">
            <router-link to="/main">
              <div class="logoImg"></div>
              <div>
                <p>안양도시공사</p>
                <p>통합주차포털</p>
              </div>
            </router-link>
          </h1>
          <article class="searchBar">
            <label for="search" class="hidden">검색어를 입력하세요.</label>
            <input
              type="text"
              id="search"
              title="검색창 입력"
              placeholder="검색어를 입력하세요."
              v-model="searchKeyword"
              @keyup.enter="search"
              @focus="focused"
              @blur="focusOut"
            />
            <button type="button" class="button searchBtn" @click="search"><span class="hidden">검색</span></button>
          </article>
          <div class="rightArea">
            <article class="utill">
              <!-- Logout -->
              <ul v-if="isLogin">
                <li class="welcome">
                  <span>{{ empNm }}</span
                  >님
                </li>
                <li>
                  <button type="button" class="btn logout" @click="actionLogout">로그아웃</button>
                </li>
                <li>
                  <router-link
                    :to="{ path: isBusiness ? '/mypagebusiness' : '/mypagegeneral' }"
                    class="btn green mypage"
                    title="마이페이지"
                    >마이페이지
                  </router-link>
                </li>
              </ul>

              <!-- Login -->
              <ul v-else>
                <li>
                  <router-link to="/ssomemberlogin" class="btn blue login" title="로그인">로그인 </router-link>
                </li>
                <li>
                  <router-link to="/join" class="btn green join" title="회원가입">회원가입</router-link>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </div>
      <!--//top-->

      <!--bottom-->
      <div class="bottom">
        <!--gnb-->
        <nav class="gnb">
          <ul class="depth01">
            <li
              v-for="(item, index) in firDepth"
              :key="index"
              @click="modalHide"
              @mouseover="openGnbmenu(item)"
              @mouseleave="closeGnbmenu(item)"
            >
              <router-link :to="item.link">{{ item.title }}</router-link>
              <article class="gnbDiv" v-if="item.showMenu">
                <div class="summary">
                  <div class="txtArea">
                    <strong>{{ item.title }}</strong>
                  </div>
                </div>
                <ul class="depth02">
                  <li v-for="(secDepth, index) in item.menuList" :key="index">
                    <router-link
                      :to="secDepth.link"
                      :class="{
                        link: secDepth.link === '/qna',
                      }"
                      >{{ secDepth.title }}
                    </router-link>
                    <ul class="depth03">
                      <li v-for="(trdDepth, index) in secDepth.menuList" :key="index">
                        <router-link :to="trdDepth.link">{{ trdDepth.title }}</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </article>
            </li>
          </ul>
        </nav>
        <!--//gnb-->
        <div class="inner">
          <button v-if="!idAdmin" class="siteMapBtn" @click="siteMapBtn">
            <span class="hidden">사이트맵</span>
          </button>
          <button v-else class="siteMapBtn admin" @click="siteMapBtn">
            <span class="hidden">사이트맵</span>
          </button>
          <!--sitemap-->
          <div class="siteMap" v-if="modalShow" @emitHideModal="modalShow = false">
            <div class="overay" @click="modalHide">
              <div class="stieMapBox">
                <div class="row">
                  <div class="column" v-for="(item, index) in firDepth" :key="index">
                    <div class="menuArea">
                      <router-link :to="item.link">{{ item.title }}</router-link>
                      <ul class="menu" @click="modalHide" @keydown.enter="modalHide">
                        <li v-for="(secDepth, index) in item.menuList" :key="index">
                          <router-link
                            :to="secDepth.link"
                            :class="{
                              link: secDepth.link === '/qna',
                            }"
                            >{{ secDepth.title }}
                          </router-link>
                          <ul>
                            <li v-for="(trdDepth, index) in secDepth.menuList" :key="index">
                              <router-link :to="trdDepth.link">{{ trdDepth.title }}</router-link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--//bottom-->
    </header>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import axios from '../../util/axios';
import openModal from '@/util/modalMessageSetter';

export default {
  data() {
    return {
      firDepth: [],
      modalShow: false,
      btnmenu: false,
      searchKeyword: '',
    };
  },
  created() {
    this.getGnbMenuList();
  },

  watch: {
    isLogin() {
      this.getGnbMenuList();
    },
    $route() {
      this.btnmenu = false;
    },
  },

  methods: {
    getGnbMenuList() {
      const copy = cloneDeep(this.menuList);

      const recursion = (menu = []) => {
        for (let i = 0; i < menu.length; i++) {
          if (menu[i].title === '주차서비스') {
            if (this.loginType == 'ROLE_STORE') {
              menu[i].link = menu[i].link.bsn;
            } else {
              menu[i].link = menu[i].link.usr;
            }
          }

          if (!this.isLogin) {
            if (menu[i].auth === 'ROLE_ADMIN' || menu[i].link.includes('mypage') || menu[i].link === '/reductionlist') {
              menu[i] = '';
            }
          } else {
            if (this.loginType === 'ROLE_CITYHALL') {
              if (menu[i].auth === 'ROLE_ADMIN' || menu[i].auth === 'ROLE_STORE') {
                menu[i] = '';
              }
            } else {
              if (!this.idAdmin && menu[i].auth !== this.loginType && menu[i].auth !== undefined) {
                menu[i] = '';
              }
            }
          }

          recursion(menu[i].menuList);
        }
      };

      recursion(copy);

      this.firDepth = copy.filter(v => {
        if (v.menuList) {
          v.menuList = v.menuList.filter(i => i !== '');

          v.menuList.filter(item => {
            if (item.menuList) {
              item.menuList = item.menuList.filter(j => j !== '');
            }
          });
        }
        return v;
      });
    },

    // gnbMenu Open
    openGnbmenu(item) {
      item.showMenu = true;
    },
    // gnbMenu Close
    closeGnbmenu(item) {
      item.showMenu = false;
    },
    siteMapBtn() {
      this.modalShow = !this.modalShow;
    },
    modalHide() {
      this.modalShow = false;
    },
    menubtn() {
      this.btnmenu = !this.btnmenu;
    },
    closeResult() {
      this.btnmenu = false;
    },
    async logout() {
      await axios('post', '/api/actionLogout.json');
      this.$store.dispatch('logout', undefined);
    },

    async actionLogout() {
      if (this.isSSO && process.env.VUE_APP_SMARTRO_INIT === 'REAL') {
        location.href = '/SSOService.do?pname=spLogout';
        return;
      }

      await this.logout();
      openModal('로그아웃 하였습니다.', 'warning', '/main');
    },

    focused(e) {
      e.target.placeholder = '';
    },
    focusOut(e) {
      e.target.placeholder = '검색어를 입력하세요';
    },

    search() {
      if (search.value !== '') {
        this.$router
          .push({
            path: 'searchlist',
            query: { q: this.searchKeyword },
          })
          .catch(() => {});
      }
      this.searchKeyword = '';
    },
  },

  computed: {
    ...mapGetters({
      menuList: 'getMenuList',
    }),
    isLogin() {
      return this.$store.state.isLogin;
    },
    isBusiness() {
      return this.$store.state.empDiv === 'BSN';
    },
    idAdmin() {
      return this.$store.state.authorCode === 'ROLE_ADMIN';
    },
    empNm() {
      return this.$store.state.empNm;
    },
    loginType() {
      return this.$store.state.authorCode ? this.$store.state.authorCode : '';
    },
    isSSO() {
      return this.$store.getters.isSSO;
    },
  },
};
</script>
