<template>
  <div>
    <section class="alert notmatch">
      <article class="alertCont">
        <div class="inner">
          <pre>{{ msg }}</pre>
          <article class="btnArea">
            <button class="btn border" style="padding: 0 30px" @click="closeConfirmModal(false)">
              {{ confirmCancelMessage }}
            </button>
            <button ref="confirmRef" class="btn green" style="padding: 0 30px" @click="closeConfirmModal(true)">
              {{ confirmOkMessage }}
            </button>
          </article>
        </div>
      </article>
      <article class="alertBottom">
        <button class="btn closeBtn" @click="closeConfirmModal(false)">
          <span class="hidden">닫기</span>
        </button>
      </article>
    </section>
    <section class="dim">
      <slot name="dimarea"></slot>
    </section>
  </div>
</template>

<script>
import modalMixins from '@/mixins/modalMixins.vue';

export default {
  mixins: [modalMixins],

  mounted() {
    this.$focusButton(this.$refs.confirmRef);
  },

  methods: {
    closeConfirmModal(status) {
      // 모달이 연속적으로 호출 될 경우를 위해
      const confirmOkHandler = this.$store.state.confirmOkHandler;
      this.$initModal();
      if (status) {
        confirmOkHandler();
      }
    },
  },
};
</script>
