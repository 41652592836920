<template>
  <div>
    <section class="alert">
      <article class="alertCont">
        <div class="inner">
          <pre>{{ msg }}</pre>
          <article class="btnArea">
            <button ref="warningRef" class="btn blue" @mouseup="$initModal" @keyup.enter="$initModal">확인</button>
          </article>
        </div>
      </article>
      <article class="alertBottom">
        <button class="btn closeBtn" @click="$initModal">
          <span class="hidden">닫기</span>
        </button>
      </article>
    </section>
    <section class="dim">
      <slot name="dimarea"></slot>
    </section>
  </div>
</template>

<script>
import modalMixins from '@/mixins/modalMixins.vue';

export default {
  mixins: [modalMixins],

  mounted() {
    this.$focusButton(this.$refs.warningRef);
  },
};
</script>

<style scoped></style>
