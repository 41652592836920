var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.name == 'main')?_c('div',[_c('router-view')],1):(_vm.$route.meta.category)?_c('div',[_c('section',{class:{
      parking: _vm.$route.meta.category[0] === '공영주차장',
      prepayment: _vm.$route.meta.category[0] === '주차요금 정산',
      reduction: _vm.$route.meta.category[0] === '요금감면 자격확인',
      period: _vm.$route.meta.category[0] === '주차서비스',
      board: _vm.$route.meta.category[0] === '정보마당',
      mypage: _vm.$route.meta.category[0] === '마이페이지',
      manage: _vm.$route.meta.category[0] === '관리자메뉴',
      system: _vm.$route.meta.category[0] === '시스템관리',
      cityhall: _vm.$route.meta.category[0] === '시스템관리(시청)',
    },attrs:{"id":"category"}},[_c('div',{staticClass:"inner"},[_c('h2',[_vm._v(_vm._s(_vm.$route.meta.category[0]))]),_c('top-navigation')],1)]),_c('router-view')],1):_c('div',[_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }