<template>
  <div v-if="$route.name == 'main'">
    <router-view></router-view>
  </div>
  <div v-else-if="$route.meta.category">
    <section
      id="category"
      :class="{
        parking: $route.meta.category[0] === '공영주차장',
        prepayment: $route.meta.category[0] === '주차요금 정산',
        reduction: $route.meta.category[0] === '요금감면 자격확인',
        period: $route.meta.category[0] === '주차서비스',
        board: $route.meta.category[0] === '정보마당',
        mypage: $route.meta.category[0] === '마이페이지',
        manage: $route.meta.category[0] === '관리자메뉴',
        system: $route.meta.category[0] === '시스템관리',
        cityhall: $route.meta.category[0] === '시스템관리(시청)',
      }"
    >
      <div class="inner">
        <h2>{{ $route.meta.category[0] }}</h2>
        <top-navigation></top-navigation>
      </div>
    </section>
    <router-view></router-view>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue';

export default {
  data() {
    return {};
  },
  components: {
    TopNavigation,
  },
};
</script>
