<template>
  <section id="APP">
    <gnb-area v-if="!['/tokeninfo', '/ssoerror', '/admin'].includes($route.path)" />
    <main-layout />
    <footer-area v-if="!['/tokeninfo', '/ssoerror', '/admin'].includes($route.path)" />
    <AlertCheck v-if="alertType === 'check'" />
    <AlertWarning v-else-if="alertType === 'warning'" />
    <AlertConfirm v-else-if="alertType === 'confirm'" />
  </section>
</template>
<script>
import GnbArea from './html/layout/GnbArea.vue';
import MainLayout from './html/layout/MainLayout.vue';
import FooterArea from './html/layout/FooterArea.vue';
import AlertCheck from '@/components/AlertCheck.vue';
import AlertWarning from '@/components/AlertWarning.vue';
import AlertConfirm from '@/components/AlertConfirm.vue';

export default {
  components: {
    AlertConfirm,
    AlertWarning,
    AlertCheck,
    GnbArea,
    MainLayout,
    FooterArea,
  },

  computed: {
    alertType() {
      return this.$store.state.alertType;
    },
  },
};
</script>
<style></style>
