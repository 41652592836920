<template>
  <main id="subPage">
    <section id="container">
      <div class="inner">
        <section id="content">
          <h3 class="pageTitle">주차장 검색</h3>

          <div id="tabs">
            <ul class="tabMenu">
              <li class="active">
                <router-link to="/parkingmap" title="지도 검색">지도 검색</router-link>
              </li>
              <li>
                <router-link to="/parkinglist" title="주소 검색">주소 검색</router-link>
              </li>
            </ul>
            <section class="tabCont">
              <article class="parkRadio">
                <dl role="radiogroup">
                  <dd>
                    <input
                      type="radio"
                      id="all"
                      v-model="distance"
                      name="distance"
                      value=""
                      @change="distanceCheck()"
                    />
                    <label for="all">전체</label>
                  </dd>
                  <dd>
                    <input
                      type="radio"
                      id="d1Km"
                      v-model="distance"
                      name="distance"
                      value="1000"
                      @change="distanceCheck()"
                    />
                    <label for="d1Km">1km</label>
                  </dd>
                  <dd>
                    <input
                      type="radio"
                      id="d3Km"
                      v-model="distance"
                      name="distance"
                      value="3000"
                      @change="distanceCheck()"
                    />
                    <label for="d3Km">3km</label>
                  </dd>
                  <dd>
                    <input
                      type="radio"
                      id="d5Km"
                      v-model="distance"
                      name="distance"
                      value="5000"
                      @change="distanceCheck()"
                    />
                    <label for="d5Km">5km</label>
                  </dd>
                  <dd>
                    <input
                      type="radio"
                      id="d30Km"
                      v-model="distance"
                      name="distance"
                      value="30000"
                      @change="distanceCheck()"
                    />
                    <label for="d30Km">30km</label>
                  </dd>
                  <dd>
                    <input
                      type="radio"
                      id="d50Km"
                      v-model="distance"
                      name="distance"
                      value="50000"
                      @change="distanceCheck()"
                    />
                    <label for="d50Km">50km</label>
                  </dd>
                </dl>
              </article>

              <div class="parkArea">
                <!--지도-->
                <div id="mapContainer">
                  <!-- 로드뷰 화면 들어갈 곳 -->
                  <div id="rvWrapper">
                    <div id="roadview" class="mapWH100"></div>
                    <div id="close" title="로드뷰닫기" @click="closeRoadview()"><span class="img"></span></div>
                  </div>

                  <!-- Map 화면 들어갈 곳 -->
                  <div class="visualMap" style="background-color: #f8f8f8" id="mapWrapper">
                    <div id="map" class="mapWH100">
                      <KakaoMap
                        :centerPositions="mapCenterPosition"
                        :parkingList="parkingList"
                        @kakaoMapMarker="kakaoMapMarker"
                      />
                    </div>
                    <div id="roadviewControl" :class="{ active: active === true }" @click="setRoadviewRoad()"></div>

                    <div class="fOption">
                      <ul>
                        <li><span class="blue"></span>여유</li>
                        <li><span class="org"></span>혼잡</li>
                        <li><span class="red"></span>매우혼잡</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!--//지도-->

                <div class="parkPopup" v-bind:class="{ active: toggle }">
                  <div class="parkHead">
                    <p>{{ def.PARKING_NM }} {{ def.PARKING_GUBUN }}주차장</p>
                  </div>
                  <div class="parkCont">
                    <img :src="ckImg" alt="주차장 이미지" />
                    <div class="table park-table">
                      <table>
                        <caption>
                          주차장 상세정보 테이블
                        </caption>
                        <colgroup>
                          <col style="width: 25%" />
                          <col style="width: 25%" />
                          <col style="width: 25%" />
                          <col style="width: auto" />
                        </colgroup>
                        <tbody>
                          <tr>
                            <th scope="row">유형</th>
                            <td>{{ def.CATEGORIES }}</td>
                            <th scope="row">급지</th>
                            <td>{{ def.GRADE }}</td>
                          </tr>
                          <tr>
                            <th scope="row">주차장 주소</th>
                            <td colspan="3">{{ def.CELL_ADDR_LOAD }}</td>
                          </tr>
                          <tr>
                            <th scope="row" rowspan="4">요금안내 (10분당 요금할증)</th>
                            <th scope="col" class="sky">최초{{ def.DFLT_TM }}분</th>
                            <th scope="col" class="sky">1시간까지</th>
                            <th scope="col" class="sky">2시간까지</th>
                          </tr>
                          <tr>
                            <td>{{ def.DFLT_AMT | comma }}원</td>
                            <td>{{ def.INTVL_AMT | comma }}원</td>
                            <td v-if="def.DETAIL">{{ def.DETAIL[0].INTVL_AMT | comma }}원</td>
                            <td v-else>-</td>
                          </tr>
                          <tr>
                            <th scope="col" class="sky">3시간까지</th>
                            <th scope="col" class="sky">3시간이후</th>
                            <th scope="col" class="sky">-</th>
                          </tr>
                          <tr>
                            <td v-if="def.DETAIL">{{ def.DETAIL[1].INTVL_AMT | comma }}원</td>
                            <td v-else>-</td>
                            <td v-if="def.DETAIL">{{ def.DETAIL[2].INTVL_AMT | comma }}원</td>
                            <td v-else>-</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <th scope="row">일일최대요금</th>
                            <td colspan="3">{{ def.DAY_PARKING_AMT | comma }}원</td>
                          </tr>
                          <tr>
                            <th scope="row">1일선불요금<br />(평일/주말)</th>
                            <td colspan="3">
                              <div v-if="!def.SEASON">
                                <div v-if="def.ONEDAY_AMT !== def.WEND_ONEDAY_AMT">
                                  평일: {{ def.ONEDAY_AMT | comma }}원 / 주말: {{ def.WEND_ONEDAY_AMT | comma }}원
                                </div>
                                <div v-else>{{ def.ONEDAY_AMT | comma }}원</div>
                              </div>
                              <div v-else>
                                <div v-for="(day, j) in def.SEASON" :key="j">
                                  <span style="font-weight: bold">{{ day.MONTH }}월</span>&nbsp;
                                  <span v-if="day.WDAYS_ONEDAY_AMT !== day.WEND_ONEDAY_AMT"
                                    >{{ day.WDAYS_ONEDAY_AMT | comma }}원 / {{ day.WEND_ONEDAY_AMT | comma }}원
                                  </span>
                                  <span v-else>{{ day.WDAYS_ONEDAY_AMT | comma }}원</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">월정기요금</th>
                            <td colspan="3">{{ def.PERIOD_TICKET_AMT | comma }}원</td>
                          </tr>
                          <tr>
                            <th scope="row">이용시간</th>
                            <td colspan="3">
                              <div v-if="def.SEASON">
                                <div v-for="(day, j) in def.SEASON" :key="j">
                                  <p>
                                    <span style="font-weight: bold">{{ day.MONTH }}월</span><br />
                                    {{ day.WDAYS_START_TM }}~{{ day.WDAYS_END_TM }}
                                    <span v-if="day.WDAYS_END_TM !== day.WEND_END_TM && day.WEND_END_TM !== '00:00'">
                                      (평일) / {{ day.WEND_START_TM }}~{{ day.WEND_END_TM }} (주말)
                                    </span>
                                    <span v-if="day.WDAYS_END_TM === day.WEND_END_TM && day.WEND_END_TM !== '00:00'">
                                      (월~{{ def.PARKING_END_DAY }})
                                    </span>
                                    <span v-if="day.WEND_END_TM === '00:00'">(주말미운영)</span>
                                  </p>
                                </div>
                              </div>
                              <div v-else>
                                <p v-if="def.WDAYS_END_TM === '23:59'">24시간 운영 (월~{{ def.PARKING_END_DAY }})</p>
                                <p v-else>
                                  {{ def.WDAYS_START_TM }}~{{ def.WDAYS_END_TM }} (월~{{ def.PARKING_END_DAY }})
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">무료시간</th>
                            <td colspan="3">운영시간 외 무료</td>
                          </tr>
                          <tr>
                            <th scope="row">주차면수</th>
                            <td>{{ def.CELL_CNT }}</td>
                            <th scope="row">가용주차수</th>
                            <td v-if="def.PARK_COUNT == null">0</td>
                            <td v-else>{{ def.PARK_COUNT }}</td>
                          </tr>
                          <tr>
                            <th scope="row">장애인</th>
                            <td v-if="def.HNDCAP_CELL_CNT == null">0</td>
                            <td v-else>{{ def.HNDCAP_CELL_CNT }}</td>
                            <th scope="row">전기차</th>
                            <td v-if="def.ELEC_CELL_CNT == null">0</td>
                            <td v-else>{{ def.ELEC_CELL_CNT }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="cover">
                    <img src="../../../assets/images/ico/ico-parkdetail.svg" alt="주차장 돋보기 아이콘" />
                    <span>주차장을 선택하시면<br />상세정보를 확인하실 수 있습니다.</span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </section>
  </main>
</template>

<script>
import KakaoMap from '@/components/KakaoMap.vue';
import axios from '@/util/axios';

export default {
  components: { KakaoMap },

  data() {
    return {
      toggle: false,
      active: false,
      overlayOn: false,

      distance: '',
      def: {},
      markers: [],
      parkingList: undefined,
      parkingLocationValue: [],
      positons: [],
      customOverlay: [],
      mapCenterPosition: [37.3942412, 126.9568605],
      marker: null,
      map: null,
      ckImg: null,
      imageSize: null,
      rv: null,
      rvClient: null,
      container: null,
      userLat: 37.3942412,
      userLng: 126.9568605,
      kakaoVali: -1,
      clickedMarkerIdx: -1,
      clickChk: false,
    };
  },

  created() {
    this.getParkingList();
  },

  watch: {
    def() {
      if (this.def.DETAIL) {
        const length = this.def.DETAIL.length;
        if (length < 3) {
          for (let i = 0; i < 3 - length; i++) {
            this.def.DETAIL.push(this.def.DETAIL[length - 1]);
          }
        }
      } else {
        this.def.DETAIL = [];
        for (let i = 0; i < 3; i++) {
          this.def.DETAIL.push({ INTVL_AMT: this.def.INTVL_AMT });
        }
      }
    },
  },

  methods: {
    async getParkingList() {
      const res = await axios('get', '/api/parking/searchParkingList');
      this.parkingList = res.data.parkingList;
    },

    checkImg(repoId, filePath, saveNm, fileExt) {
      if (repoId == null || filePath == null || saveNm == null || fileExt == null) {
        this.ckImg = this.noParkingImage();
      } else {
        this.ckImg = '/api/parking/atchfile/' + repoId + '/' + filePath + '/' + saveNm + '.' + fileExt;
      }
    },
    noParkingImage() {
      return require('../../../assets/images/map/noparking.png');
    },

    // 확대축소 레벨 별 이미지사이즈 저장
    distanceCheck() {
      const searchDistance = this.distance;

      if (searchDistance == '1000') {
        this.setMarkersWithinKm(1.0);
      } else if (searchDistance == '3000') {
        this.setMarkersWithinKm(3.0);
      } else if (searchDistance == '5000') {
        this.setMarkersWithinKm(5.0);
      } else if (searchDistance == '30000') {
        this.setMarkersWithinKm(30.0);
      } else if (searchDistance == '50000') {
        this.setMarkersWithinKm(50.0);
      } else {
        this.markers.map(marker => marker.setMap(this.map));
        const bounds = new kakao.maps.LatLngBounds();
        for (let i = 0; i < this.markers.length; i++) {
          bounds.extend(this.markers[i].getPosition());
        }
        this.map.setBounds(bounds);
      }
    },

    setMarkersWithinKm(km) {
      if (this.markers != []) {
        this.markers.map(marker => marker.setMap(null));
      }

      const bounds = new kakao.maps.LatLngBounds();

      for (let i = 0; i < this.markers.length; i++) {
        const _pos = this.markers[i].getPosition();
        if (this.getDistanceFromLatLonInKm(_pos.getLat(), _pos.getLng(), this.userLat, this.userLng) <= km) {
          this.markers[i].setMap(this.map);
          bounds.extend(_pos);
        }
      }
      if (bounds.ha != Infinity) {
        this.map.setBounds(bounds);
      }
    },

    getDistanceFromLatLonInKm(lat1, lng1, lat2, lng2) {
      // lat1, lng1 비교대상 위치정보  || lat2, lng2 기준 위치정보
      // Radius of the earth in km
      var R = 6371;
      // deg2rad below
      var dLat = this.deg2rad(lat2 - lat1);
      var dLon = this.deg2rad(lng2 - lng1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      // Distance in km
      var d = R * c;

      return d;
    },
    deg2rad(x) {
      return x * (Math.PI / 180);
    },

    //kakao에서 markers 만든 후 클릭 이벤트 추가
    kakaoMapMarker(marker, map, positions, customOverlay) {
      this.markers = marker;
      this.map = map;
      this.positions = positions;
      this.customOverlay = customOverlay;

      for (let i = 0; i < this.markers.length; i++) {
        if (this.parkingList[[i]].PARK_COUNT == null) {
          this.parkingList[[i]].PARK_COUNT = 0;
        } else {
          this.parkingList[[i]].PARK_COUNT =
            this.parkingList[[i]].PARK_COUNT < 0 ? 0 : this.parkingList[[i]].PARK_COUNT;
        }

        kakao.maps.event.addListener(this.markers[i], 'mouseover', () => {
          // 마커 호버 이벤트
          this.customOverlay[i].setMap(this.map);
        });
        kakao.maps.event.addListener(this.markers[i], 'mouseout', () => {
          // 마커 호버아웃 이벤트
          this.customOverlay[i].setMap(null);
        });
        kakao.maps.event.addListener(this.markers[i], 'click', () => {
          // 마커 클릭 이벤트
          this.makeClickListener(i);
        });
      }

      // 로드뷰 마커 이미지를 생성합니다
      var rvMarkImage = new kakao.maps.MarkerImage(
        'https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png',
        new kakao.maps.Size(37, 44),
        {
          // 스프라이트 이미지를 사용합니다.
          // 스프라이트 이미지 전체의 크기를 지정하고
          spriteSize: new kakao.maps.Size(1666, 168),
          // 사용하고 싶은 영역의 좌상단 좌표를 입력합니다.
          // background-position으로 지정하는 값이며 부호는 반대입니다.
          spriteOrigin: new kakao.maps.Point(705, 114),
          offset: new kakao.maps.Point(13, 46),
        },
      );

      // 드래그가 가능한 마커를 생성합니다
      this.marker = new kakao.maps.Marker({
        image: rvMarkImage,
        position: new kakao.maps.LatLng(this.userLat, this.userLng),
        draggable: true,
      });

      // 마커에 dragend 이벤트를 등록합니다
      kakao.maps.event.addListener(this.marker, 'dragend', mouseEvent => {
        // 현재 마커가 놓인 자리의 좌표입니다
        var position = this.marker.getPosition();

        // 마커가 놓인 위치를 기준으로 로드뷰를 설정합니다
        this.toggleRoadview(position);
      });

      //지도에 클릭 이벤트를 등록합니다
      kakao.maps.event.addListener(this.map, 'click', mouseEvent => {
        // 지도 위에 로드뷰 도로 오버레이가 추가된 상태가 아니면 클릭이벤트를 무시합니다
        if (!this.overlayOn) {
          return;
        }

        // 클릭한 위치의 좌표입니다
        var position = mouseEvent.latLng;

        // 마커를 클릭한 위치로 옮깁니다
        this.marker.setPosition(position);

        // 클락한 위치를 기준으로 로드뷰를 설정합니다
        this.toggleRoadview(position);
      });

      this.myPlace();
    },

    myPlace() {
      let locPosition = null;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.userLat = position.coords.latitude;
            this.userLng = position.coords.longitude;

            locPosition = new kakao.maps.LatLng(this.userLat, this.userLng);

            this.displayMarker(locPosition);
          },
          err => {
            locPosition = new kakao.maps.LatLng(this.userLat, this.userLng);
            this.displayMarker(locPosition);
          },
        );
      } else {
        // HTML5의 GeoLocation을 지원하지 않는 브라우저에서 마커 표시 위치와 인포윈도우 내용을 설정합니다

        locPosition = new kakao.maps.LatLng(this.userLat, this.userLng);

        this.displayMarker(locPosition);
      }
    },

    displayMarker(locPosition) {
      new kakao.maps.Marker({
        map: this.map,
        position: locPosition,
      });
      this.map.setCenter(locPosition);
    },

    customOverlayPinned(marker, customOverlay) {
      kakao.maps.event.addListener(marker, 'mouseout', () => {
        customOverlay.setMap(this.map);
      });
      customOverlay.setMap(this.map);
    },

    customOverlayRemoved(marker, customOverlay) {
      kakao.maps.event.addListener(marker, 'mouseout', () => {
        customOverlay.setMap(null);
      });
      customOverlay.setMap(null);
    },

    makeClickListener(idx) {
      if (this.clickedMarkerIdx == -1) {
        this.customOverlayPinned(this.markers[idx], this.customOverlay[idx]);
        this.clickedMarkerIdx = idx;
        this.toggle = true;
      } else if (this.clickedMarkerIdx != idx) {
        this.customOverlayRemoved(this.markers[this.clickedMarkerIdx], this.customOverlay[this.clickedMarkerIdx]);
        this.customOverlayPinned(this.markers[idx], this.customOverlay[idx]);
        this.clickedMarkerIdx = idx;
        this.toggle = true;
      } else {
        this.customOverlayRemoved(this.markers[this.clickedMarkerIdx], this.customOverlay[this.clickedMarkerIdx]);
        this.clickedMarkerIdx = -1;
        this.toggle = false;
      }
      this.def = this.parkingList[idx];

      this.checkImg(this.def.REPO_ID, this.def.FILE_PATH, this.def.SAVE_NM, this.def.FILE_EXT);
    },

    // 지도 위의 로드뷰 버튼을 눌렀을 때 호출되는 함수입니다
    setRoadviewRoad() {
      this.active = !this.active;

      //로드뷰를 표시할 div
      const rvContainer = document.getElementById('roadview');
      // 지도와 로드뷰를 감싸고 있는 div
      this.container = document.getElementById('mapContainer');

      // 로드뷰 객체를 생성
      this.rv = new kakao.maps.Roadview(rvContainer);

      // 좌표로부터 로드뷰 파노라마 ID를 가져올 로드뷰 클라이언트 객체를 생성
      this.rvClient = new kakao.maps.RoadviewClient();

      // 로드뷰에 좌표가 바뀌었을 때 발생하는 이벤트를 등록
      kakao.maps.event.addListener(this.rv, 'position_changed', () => {
        // 현재 로드뷰의 위치 좌표를 얻어옵니다
        const rvPosition = this.rv.getPosition();

        // 지도의 중심을 현재 로드뷰의 위치로 설정합니다
        this.map.setCenter(rvPosition);

        // 지도 위에 로드뷰 도로 오버레이가 추가된 상태이면
        if (this.overlayOn) {
          // 마커의 위치를 현재 로드뷰의 위치로 설정합니다
          this.marker.setPosition(rvPosition);
        }
      });

      const position = this.map.getCenter();

      // 버튼이 눌린 상태가 아니면
      if (this.active) {
        // 로드뷰 도로 오버레이가 보이게 합니다
        this.overlayOn = true;

        // 지도 위에 로드뷰 도로 오버레이를 추가합니다
        this.map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);

        // 지도 위에 마커를 표시합니다
        this.marker.setMap(this.map);

        // 마커의 위치를 지도 중심으로 설정합니다
        this.marker.setPosition(position);

        this.toggleRoadview(position);
      } else {
        // 로드뷰 도로 오버레이를 제거합니다
        this.overlayOn = false;

        // 지도 위의 로드뷰 도로 오버레이를 제거합니다
        this.map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);

        this.marker.setMap(null);

        this.container.className = '';
        this.map.relayout();
        this.map.setCenter(position);
      }
    },
    toggleRoadview(position) {
      this.rvClient.getNearestPanoId(position, 60, panoId => {
        // 파노라마 ID가 null 이면 로드뷰를 숨깁니다
        if (panoId === null) {
          this.container.className = '';
        } else {
          if (this.container.className.indexOf('view_roadview') === -1) {
            this.container.className = 'view_roadview';
          }
          // panoId로 로드뷰를 설정합니다
          this.rv.setPanoId(panoId, position);
          this.map.setLevel(4);
        }
        // 지도의 크기가 변경되었기 때문에 relayout 함수를 호출합니다
        this.map.relayout();

        // 지도의 너비가 변경될 때 지도중심을 입력받은 위치(position)로 설정합니다
        this.map.setCenter(position);
      });
    },

    closeRoadview() {
      const position = this.marker.getPosition();
      this.toggleMapWrapper(true, position);
    },
  },
};
</script>

<style lang="scss">
.mapWH100 {
  width: 100%;
  height: 100%;
}

#mapWrapper {
  width: 100%;
  height: 700px;
  z-index: 1;
}

#rvWrapper {
  width: 50%;
  height: 700px;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}

#mapContainer.view_roadview #mapWrapper {
  width: 50%;
}

@media screen and (max-width: 768px) {
  #mapContainer {
    height: 500px;
  }

  #mapWrapper {
    height: 500px;
  }
  #rvWrapper {
    height: 500px;
  }
}
</style>
