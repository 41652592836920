import axios from 'axios';
import store from '../store/index.js';
import openModal from '@/util/modalMessageSetter';

const instance = axios.create({
  timeout: 30000,
});

const noCacheHeaders = {
  headers: {
    'Cache-Control': 'no-cache; no-store; must-revalidate;',
    Pragma: 'no-cache',
  },
};

const axiosHook = async (method, url, params, config = {}) => {
  let response = null;
  try {
    switch (method) {
      case 'post':
        response = await instance.post(url, params, config);
        break;
      case 'get':
        response = await instance.get(url, {
          params,
          ...config,
          ...noCacheHeaders,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    if (!response) {
      console.error(error);
      return;
    }
  }
  if (response.data.sessionExpired) {
    let url = '';
    if (store.state.empDiv == 'USR') {
      url = '/ssomemberlogin';
    } else if (store.state.empDiv == 'BSN') {
      url = '/businessmemberlogin';
    } else {
      url = '/ssomemberlogin';
    }
    store.dispatch('logout', null);
    openModal('다시 로그인 해주세요.', 'warning', url);
    return;
  }

  return response;
};

export default axiosHook;
