<template>
  <footer id="footer">
    <div class="inner">
      <div class="flex">
        <h1 class="logo">
          <a @click="openSite('https://www.auc.or.kr/base/main/view')">
            <div class="flogoImg"></div>
          </a>
        </h1>
        <div class="footUtil">
          <ul class="link">
            <li>
              <button title="개인정보처리방침" @click="ispersonal"><b>개인정보처리방침</b></button>
            </li>
            <li>
              <button title="이메일수집거부" @click="EmailModal">이메일수집거부</button>
            </li>
            <li>
              <button title="저작권보호정책" @click="copyrightModalOpen">저작권보호정책</button>
            </li>
          </ul>
          <address>
            <ul>
              <li>[14053] 경기도 안양시 동안구 시민대로 235(관양동)</li>
              <li>대표번호:031-389-5326</li>
            </ul>
            <br />
            <ul>
              <li>사업자번호 138 83 01705</li>
              <li>안양시</li>
              <li>대표자명: 최대호</li>
            </ul>
          </address>
          <p class="copyright">Copyright © 안양시통합주차포털 All rights reserved.</p>
        </div>
      </div>
      <div class="famSelect">
        <label for="familySite" class="hidden">안양시 패밀리 사이트</label>
        <select
          id="familySite"
          @change="goExternalSite"
          @keydown.up="eventStem($event)"
          @keydown.right="eventStem($event)"
          @keydown.down="eventStem($event)"
          @keydown.left="eventStem($event)"
          ref="moveSite"
        >
          <option value="/main" selected>FAMILY SITE</option>
          <option value="https://www.auc.or.kr/base/main/view">안양도시공사</option>
        </select>
      </div>
    </div>

    <a class="btnTop" @click="$vuetify.goTo('header')"> <i class="icon"></i><span>TOP</span> </a>

    <showModal v-if="emailModalShow">
      <template #info> 이메일무단수집거부</template>
      <template #content>
        <article>
          <div class="terms">
            <strong
              >본 홈페이지에 게재된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로
              수집되는 것을 거부하며, 이를 위반시 정보통신망이용촉진및정보보호등에관한법률 제50조의 2 규정에 의하여
              1천만원이하의 벌금형에 처함을 유념하시기 바랍니다.</strong
            >
            <p>
              <em>①</em> 누구든지 전자우편 주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으 로 전자우편
              주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편 주소를 수 집하여서는 아니된다.
            </p>
            <p><em>②</em> 누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매 유통하여서는 아니된다.</p>
            <p>
              <em>③</em> 누구든지 제1항 및 제2항의 규정에 의하여 수집, 판매 및 유통이 금지된 전장편주소임을 알고 이를
              정보전송에 이용하여서는 아니된다.
            </p>
          </div>
        </article>
      </template>
      <template #button>
        <button class="btn closeBtn" @click="CloseBtn"><span class="hidden">닫기</span></button>
      </template>
    </showModal>

    <showModal v-if="personalModalShow">
      <template #info> 개인정보처리방침</template>
      <template #content>
        <article>
          <div class="terms">
            <strong></strong>
          </div>
        </article>
      </template>
      <template #button>
        <button class="btn closeBtn" @click="CloseBtn"><span class="hidden">닫기</span></button>
      </template>
    </showModal>

    <showModal v-if="copyrightModalShow">
      <template #info> 저작권보호정책</template>
      <template #content>
        <article>
          <div class="terms">
            <strong></strong>
          </div>
        </article>
      </template>
      <template #button>
        <button class="btn closeBtn" @click="CloseBtn"><span class="hidden">닫기</span></button>
      </template>
    </showModal>
  </footer>
</template>

<script>
import showModal from '../../components/PopupType1.vue';

export default {
  data() {
    return {
      emailModalShow: false,
      personalModalShow: false,
      copyrightModalShow: false,
    };
  },

  components: {
    showModal,
  },

  methods: {
    openSite(url) {
      window.open(url, '_blank');
    },

    goExternalSite() {
      const agent = navigator.userAgent.toUpperCase();
      let Safari = false;

      if (agent.indexOf('SAFARI') >= 0) {
        if (agent.indexOf('CHROME') >= 0) {
          Safari = false;
        } else {
          Safari = true;
        }
      }

      if (/Mobi/i.test(window.navigator.userAgent) || Safari) {
        location.href = this.$refs.moveSite.value;
      } else {
        window.open(this.$refs.moveSite.value, '_blank');
      }

      this.$refs.moveSite.value = '/main';
    },

    eventStem(e) {
      switch (e.keyCode) {
        case 37:
          e.preventDefault();
          break;
        case 38:
          e.preventDefault();
          break;
        case 39:
          e.preventDefault();
          break;
        case 40:
          e.preventDefault();
          break;
      }
    },

    CloseBtn() {
      this.emailModalShow = false;
      this.personalModalShow = false;
      this.copyrightModalShow = false;
    },

    EmailModal() {
      this.emailModalShow = true;
    },

    ispersonal() {
      window.open('https://www.auc.or.kr/base/contents/view?contentsNo=256&menuLevel=2&menuNo=358');
    },

    copyrightModalOpen() {
      this.copyrightModalShow = true;
    },
  },
};
</script>
