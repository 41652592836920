<script>
export default {
  methods: {
    $initModal() {
      if (this.alertType != 'confirm' && this.$store.state.confirmOkHandler) {
        this.$store.state.confirmOkHandler();
      }
      if (this.url && this.$route.path != this.url) {
        this.$router.push(this.url);
      }
      this.$store.dispatch('modalObject', null);
    },

    async $focusButton(modalButton) {
      await this.$nextTick();

      modalButton.focus();
    },
  },

  computed: {
    msg() {
      return this.$store.state.msg;
    },
    alertType() {
      return this.$store.state.alertType;
    },
    url() {
      return this.$store.state.url;
    },
    confirmCancelMessage() {
      return this.$store.state.confirmCancelMessage || '취소';
    },
    confirmOkMessage() {
      return this.$store.state.confirmOkMessage || '확인';
    },
  },
};
</script>
