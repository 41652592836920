import store from '@/store';

const openModal = (msg, alertType, url, confirmOkHandler, confirmOkMessage, confirmCancelMessage) => {
  store.dispatch('modalObject', {
    msg,
    alertType,
    url,
    confirmOkHandler,
    confirmOkMessage,
    confirmCancelMessage,
  });
};
export default openModal;
