<template>
  <main id="utilTemplet" style="background-color: #fff">
    <section id="content">
      <div class="inner">
        <article class="utilForm error">
          <article class="errorMsg">
            <strong>Page Not Found</strong>
            <p>요청하신 페이지를 찾을수가 없습니다.</p>
            <img class="error-img" src="../../../assets/images/common/img-error.svg" alt="ERROR 이미지" />
          </article>
          <article class="btnArea">
            <button type="button" class="btn green" @click="$router.push('main')">메인으로</button>
          </article>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
