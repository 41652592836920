import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import menuJson from './menuJson.json';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],

  state: {
    isLogin: false,
    authorCode: null,
    empNo: null,
    empNm: null,
    empDiv: null,
    empMobile: null,
    companyName: null,
    marketYn: null,
    msg: null,
    alertType: null,
    url: null,
    confirmOkMessage: null,
    confirmCancelMessage: null,
    confirmOkHandler: null,
    parkingObj: {},
    refundObj: {},
    mypageCheck: false,
    ssoId: null,

    menuList: menuJson.menuList,
  },

  getters: {
    getMenuList: state => state.menuList,
    isSSO: state => state.empDiv === 'SSO',
  },

  mutations: {
    authorCode: function (state, payload) {
      state.authorCode = payload;
    },
    empNo: function (state, payload) {
      state.empNo = payload;
    },
    empNm: function (state, payload) {
      state.empNm = payload;
    },
    empMobile: function (state, payload) {
      state.empMobile = payload;
    },
    empCarNo: function (state, payload) {
      state.empCarNo = payload;
    },
    zipcode: function (state, payload) {
      state.zipcode = payload;
    },
    addr: function (state, payload) {
      state.addr = payload;
    },
    addrDetail: function (state, payload) {
      state.addrDetail = payload;
    },
    isLogin: function (state, payload) {
      state.isLogin = payload;
    },
    empDiv: function (state, payload) {
      state.empDiv = payload;
    },
    companyName: function (state, payload) {
      state.companyName = payload;
    },
    marketYn: function (state, payload) {
      state.marketYn = payload;
    },
    modalObject: function (state, payload) {
      state.msg = payload?.msg;
      state.alertType = payload?.alertType;
      state.url = payload?.url;
      state.confirmOkMessage = payload?.confirmOkMessage;
      state.confirmCancelMessage = payload?.confirmCancelMessage;
      state.confirmOkHandler = payload?.confirmOkHandler;
    },

    logout: function (state, payload) {
      state.isLogin = false;
      state.authorCode = payload;
      state.empNo = payload;
      state.empNm = payload;
      state.empDiv = payload;
      state.empMobile = payload;
      state.marketYn = payload;
      state.companyName = payload;
      state.menuList = menuJson.menuList;
      state.ssoId = payload;
    },

    login: function (state, payload) {
      state.isLogin = true;
      state.authorCode = payload.authorCode;
      state.empNo = payload.empNo;
      state.empNm = payload.empNm;
      state.empDiv = payload.empDiv;
      state.empMobile = payload.empMobile;
      state.marketYn = payload.marketYn;
      state.companyName = payload.companyName;
      state.menuList = menuJson.menuList;
      state.ssoId = payload.ssoId;
    },
    parkingObj: function (state, payload) {
      state.parkingObj = payload;
    },
    refundObj: function (state, payload) {
      state.refundObj = payload;
    },
    mypageCheck: function (state, payload) {
      state.mypageCheck = payload;
    },
  },

  actions: {
    authorCode: function (context, payload) {
      context.commit('authorCode', payload);
    },
    empNo: function (context, payload) {
      context.commit('empNo', payload);
    },
    empNm: function (context, payload) {
      context.commit('empNm', payload);
    },
    isLogin: function (context, payload) {
      context.commit('isLogin', payload);
    },
    logout: function (context, payload) {
      context.commit('logout', payload);
    },
    login: function (context, payload) {
      context.commit('login', payload);
    },
    empDiv: function (context, payload) {
      context.commit('empDiv', payload);
    },
    companyName: function (context, payload) {
      context.commit('companyName', payload);
    },
    marketYn: function (context, payload) {
      context.commit('marketYn', payload);
    },
    modalObject: function (context, payload) {
      context.commit('modalObject', payload);
    },
    parkingObj: function (context, payload) {
      context.commit('parkingObj', payload);
    },
    refundObj: function (context, payload) {
      context.commit('refundObj', payload);
    },
    mypageCheck: function (context, payload) {
      context.commit('mypageCheck', payload);
    },
  },
});
